import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { setCookie, getCookieByName } from '@helpers/session'
import { analytics } from '@helpers/analytics'
import { getIsFirstTimeUser } from '@helpers/session/user'
import { COOKIE_EXPIRE_1_MONTH } from '@consts/index'
import ErrorBoundary from '@components/ErrorBoundary'
import styles from './styles.module.scss'

const StatementSection = dynamic(() => import('../components/StatementSection'))
const RichTextSection = dynamic(() => import('../components/RichTextSection'))
const Benefits = dynamic(() => import('@components/ui/Benefits'))
const ShoppableCardsSection = dynamic(
  () => import('../components/ShoppableCardsSection')
)
const ProductsBreakdown = dynamic(
  () => import('@components/ui/ProductsBreakdown')
)
const LandingPageProductsCards = dynamic(
  () => import('../components/LandingPageProductsCards')
)
const HeroBanner = dynamic(() => import('../components/HeroBanner'))
const HeroVideoBanner = dynamic(() => import('../components/HeroVideoBanner'))
const StoryTelling = dynamic(() => import('../components/StoryTelling'))
const Reviews = dynamic(() => import('../components/Reviews'))
const PageHeader = dynamic(() => import('../components/PageHeader'))

const mapToComponent = {
  imageCarousel: HeroBanner,
  shoppableCards: ShoppableCardsSection,
  heroVideoBanner: HeroVideoBanner,
  statementSection: StatementSection,
  richTextSection: RichTextSection,
  benefits: Benefits,
  productBreakdown: ProductsBreakdown,
  landingPageProductCards: LandingPageProductsCards,
  storytelling: StoryTelling,
  reviews: Reviews,
  pageHeader: PageHeader
} as const

export type PageProps = {
  type: string
} & React.ComponentProps<(typeof mapToComponent)[keyof typeof mapToComponent]>

type MappedComponentProps = PageProps & {
  rootPath: boolean
  firstTimeUser: boolean
}

function MappedComponent({ type, ...props }: MappedComponentProps) {
  if (type === 'imageCarousel') {
    const pageData = props as React.ComponentProps<typeof HeroBanner>
    return <HeroBanner {...pageData} />
  }

  if (type === 'shoppableCards') {
    const pageData = props as React.ComponentProps<typeof ShoppableCardsSection>
    return <ShoppableCardsSection {...pageData} />
  }

  if (type === 'heroVideoBanner') {
    const pageData = props as React.ComponentProps<typeof HeroVideoBanner>
    return <HeroVideoBanner {...pageData} />
  }

  if (type === 'statementSection') {
    const pageData = props as React.ComponentProps<typeof StatementSection>
    return <StatementSection {...pageData} />
  }

  if (type === 'richTextSection') {
    const pageData = props as React.ComponentProps<typeof RichTextSection>
    return <RichTextSection {...pageData} />
  }

  if (type === 'benefits') {
    const pageData = props as React.ComponentProps<typeof Benefits>
    return <Benefits {...pageData} />
  }

  if (type === 'productBreakdown') {
    const pageData = props as React.ComponentProps<typeof ProductsBreakdown>
    return <ProductsBreakdown {...pageData} />
  }

  if (type === 'landingPageProductCards') {
    const pageData = props as React.ComponentProps<
      typeof LandingPageProductsCards
    >
    return <LandingPageProductsCards {...pageData} />
  }

  if (type === 'storytelling') {
    const pageData = props as React.ComponentProps<typeof StoryTelling>
    return <StoryTelling {...pageData} />
  }

  if (type === 'reviews') {
    const pageData = props as React.ComponentProps<typeof Reviews>
    return <Reviews {...pageData} />
  }

  if (type === 'pageHeader') {
    const pageData = props as React.ComponentProps<typeof PageHeader>
    return <PageHeader {...pageData} />
  }

  return null
}

export type DynamicPagesProps = {
  handle: string
  page: PageProps[]
}

const DynamicPages = ({ handle, page }: DynamicPagesProps) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { fte } = router.query
  const firstTimeUser = getIsFirstTimeUser()
  const filteredPageData = (page || []).filter(
    pageData => !!mapToComponent[pageData.type as keyof typeof mapToComponent]
  )

  useEffect(() => {
    if (fte) {
      analytics('First Time Visitor triggered by campaign', {
        name: 'First Time Visitor triggered by campaign',
        label: 'First Time Visitor triggered by campaign'
      })
    } else if (!getCookieByName('th_LV')) {
      analytics('First Time Visitor Viewed', {
        name: 'First Time Visitor Viewed',
        label: 'First Time Visitor Viewed'
      })
      window.sessionStorage.setItem('th_ftv', 'true')
    }
    setCookie('th_LV', `${Date.now()}`, COOKIE_EXPIRE_1_MONTH)
  }, [fte, dispatch])

  useEffect(() => {
    if (handle === '/new-user') {
      analytics('New User Homepage Viewed', {
        category: 'Homepage',
        label: 'New User Homepage Viewed'
      })
    }

    if (handle === '/') {
      analytics('Existing User Homepage Viewed', {
        category: 'Homepage',
        label: 'Existing User Homepage Viewed'
      })
    }
  }, [handle])

  return (
    <div className={styles.pageWrapper}>
      {filteredPageData.map((pageData, index) => {
        const isLastItem = filteredPageData.length === index + 1
        return (
          <ErrorBoundary key={`${index.toString()}`}>
            <MappedComponent
              {...pageData}
              rootPath={/^\/(new-user)?$/.test(handle)}
              firstTimeUser={firstTimeUser}
            />
            {isLastItem && <div className={styles.pageLineSeparator}></div>}
          </ErrorBoundary>
        )
      })}
    </div>
  )
}

export default DynamicPages
